/* eslint-disable @typescript-eslint/no-explicit-any */
interface RedirectOptions {
  history?: { push: (location: { pathname: string; state?: Record<string, any> }) => void } | null;
  path: string;
  params?: Record<string, any>;
  getParams?: Record<string, string | number>;
  useQueryParams?: boolean;
  isInternal?: boolean;
}

export const historyRedirect = ({
  history = null,
  path,
  params,
  getParams = {},
  useQueryParams = false,
  isInternal = true,
}: RedirectOptions): void => {
  const urlParams = new URLSearchParams();

  Object.entries(getParams).forEach(([key, value]) => {
    const paramInPath = `:${key}`;
    const encodedValue = encodeURIComponent(String(value));

    if (path.includes(paramInPath)) {
      path = path.replace(paramInPath, encodedValue);
    } else if (useQueryParams) {
      urlParams.append(key, encodedValue);
    }
  });

  if (urlParams.toString()) {
    path += `?${urlParams.toString()}`;
  }

  if (params && Object.keys(params).length > 0 && !isInternal) {
    const paramsString = Object.entries(params)
      .map(([key, value]) => `${key}=${encodeURIComponent(String(value))}`)
      .join("-");

    path += `&params=${paramsString}`;
  }

  if (isInternal && history) {
    history.push({ pathname: path, state: params });
  } else if (!isInternal) {
    window.location.href = path;
  }
};
