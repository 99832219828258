import DownloadTemplate from "@app/common/components/DownloadTemplate";
import { TextEditor } from "@app/common/components/EditorText";
import { MultiInput } from "@app/common/components/MultiInput";
import { warningNotification } from "@app/common/components/notifications";
import { fileToBase64 } from "@app/common/utils/utils";
import routes from "@app/config/routes";
import { useDeliveryReminder, useGetPdfDetail } from "@app/services/drugDelivery/hooks";
import { OrderInfo, reminderDispensationPayload } from "@app/services/drugDelivery/types";
import { useDeliveryData } from "@app/store/useDeliverySearchData";
import { useGenericData } from "@app/store/useGenericData";
import { generateId } from "@app/utils/generateId";
import { Badge, Box, Button, FileButton, Text, TextInput } from "@mantine/core";
import { IconSend, IconUpload, IconX } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const DeliveryDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const disId = location?.state?.disId;
  const { patient } = (location?.state?.orderInfo as OrderInfo) ?? {};
  const setDataDispense = useDeliveryData(store => store.setDataDispense);
  const { type } = useDeliveryData(store => store.dataToSearch);
  const { accountName } = useGenericData(store => store.genericData);

  const [data, setData] = useState<reminderDispensationPayload>({
    emailRecipients: [String(patient.email)],
    emailSubject: "Dispensación de medicamentos",
    emailBody: `Hola, somos ${accountName}. Estimad@ usuario, a continuación compartimos el detalle de su dispensación de medicamentos. Recuerda que tu visión es nuestra razón de existir!`,
    emailAttachments: [],
    id: disId,
  });

  const [files, setFiles] = useState<
    { fileName: string; type: string; base64: string; id: string }[]
  >([]);

  const goBack = () => {
    if (type === "patient") {
      navigate(routes.getPatientOrderInformation);
    } else {
      navigate(routes.drugDelivery);
    }
    setDataDispense({ showDeliveryBy: false, orderId: null });
  };

  const {
    data: pdfOrder = {
      results: { base64: "" },
    },
  } = useGetPdfDetail({
    disId,
  });

  useEffect(() => {
    if (pdfOrder.results.base64) {
      const base64 = pdfOrder.results.base64;
      setFiles(state => [
        ...state,
        { base64, id: generateId(), fileName: "detalle_orden.pdf", type: "pdf" },
      ]);
      setData(state => ({
        ...state,
        emailAttachments: [
          ...state.emailAttachments,
          { base64, id: generateId(), fileName: "detalle_orden.pdf", type: "pdf" },
        ],
      }));
    }
  }, [pdfOrder.results.base64]);

  const createDeliveryRequest = useDeliveryReminder(async () => {
    const doAfter = () => {
      goBack();
    };

    await doAfter();
  });

  const addFile = async (file: File | null) => {
    const base64 = await fileToBase64(file);
    setFiles(state => [
      ...state,
      { base64, id: generateId(), fileName: file?.name ?? "", type: file?.type ?? "" },
    ]);
    setData(state => ({
      ...state,
      emailAttachments: [
        ...state.emailAttachments,
        { base64, id: generateId(), fileName: file?.name ?? "", type: file?.type ?? "" },
      ],
    }));
  };

  const deleteFile = (id: string) => {
    const filter = files.filter(x => x.id !== id);
    setFiles(filter);
    setData(state => ({
      ...state,
      emailAttachments: filter,
    }));
  };

  const onSubmit = async (value: reminderDispensationPayload) => {
    await createDeliveryRequest.mutateAsync(value);
  };

  const onSubmitValidations = () => {
    if (data?.emailRecipients.length === 0) {
      warningNotification("El campo 'Para' es obligatorio", "Intenta de nuevo");
      return;
    } else if (data?.emailSubject === "") {
      warningNotification("El asunto es obligatorio", "Intenta de nuevo");
      return;
    } else if (data?.emailBody === "") {
      warningNotification("El cuerpo del correo es obligatorio", "Intenta de nuevo");
      return;
    } else {
      onSubmit(data);
    }
  };

  return (
    <Box className="flex" w={"100%"} h={"100%"}>
      <Box className="flex" w={"65%"}>
        <DownloadTemplate
          goBackButton={() => {
            goBack();
          }}
          showBgColor={false}
          url={pdfOrder.results.base64.replace(/^b'/gm, "").replace(/=*'$/gm, "") as string}
          urlType="base64"
          title="Resumen de entrega de medicamentos"
          subtitle={patient?.name ?? "Katerin Barrera Ortega"}
        ></DownloadTemplate>
      </Box>
      <Box bg={"#e6f8fa80"} w={"35%"} p={"xl"} className="align-center content-center">
        <Text fz="h1" flex={1} size={"h1"} ml={"xl"} c="secondary-color" fw={"bold"}>
          Enviar
        </Text>
        <Box className="cardSearch" mx={"xl"}>
          <MultiInput
            onChange={res => {
              setData(state => ({ ...state, emailRecipients: res }));
            }}
            value={data.emailRecipients}
            label={"Para"}
            placeholder="Escribir..."
            required
          />

          <TextInput
            required
            label={"Asunto"}
            type="text"
            value={data.emailSubject}
            data-testid="paymented"
            placeholder="Escribir..."
            onChange={({ target }) => {
              setData(state => ({ ...state, emailSubject: target.value }));
            }}
          />

          <Box py={"sm"}>
            <TextEditor
              value={data.emailBody}
              onChange={res => {
                setData(state => ({ ...state, emailBody: res }));
              }}
            />
          </Box>

          <Box>
            <FileButton
              data-testid="uploadButton"
              onChange={e => {
                addFile(e);
              }}
            >
              {props => (
                <Badge
                  variant="light"
                  color={"#1a538d"}
                  mb={"xs"}
                  className="flex pointer"
                  p={"sm"}
                >
                  <Box className="flex items-center gap-2" {...props}>
                    <span className="flex gap-2">
                      Adjuntar archivos <IconUpload {...props} />
                    </span>
                  </Box>
                </Badge>
              )}
            </FileButton>
            <Box className="overflow-y-auto" h={"3rem"}>
              {files.map(file => {
                return (
                  <Badge
                    key={file.id}
                    variant="light"
                    color={"cyan"}
                    mb={"xs"}
                    className="flex gap-2"
                    w={"100%"}
                  >
                    <span className="flex" title={file.fileName}>
                      <span className="breakEllipsis" title={file.fileName}>
                        <b>{file.fileName}</b>
                      </span>
                      &nbsp;
                      <IconX onClick={() => deleteFile(file.id)} className="pointer" />
                    </span>
                  </Badge>
                );
              })}
            </Box>
          </Box>

          <Box className=" flex justify-end" w={"100%"} py={"md"}>
            <div>
              <Button
                variant="filled"
                w={"11.5rem"}
                h={"40px"}
                data-testid="reject"
                onClick={() => {
                  onSubmitValidations();
                }}
              >
                <Text py={"sm"} className="flex items-center">
                  Enviar &nbsp; <IconSend className="white" />
                </Text>
              </Button>
            </div>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DeliveryDetail;
