import { CategoriesResponse } from "@app/services/category/types";
import { IDataBiowel } from "@app/store/types";

interface Category {
  title?: string;
  name?: string;
  url?: string;
}

interface GetExternalUrlParams {
  category?: Category;
  myPayroll?: boolean;
  mySetting?: boolean;
  dataBiowel: IDataBiowel;
  allCategory: CategoriesResponse;
}

export const getExternalUrl = ({
  category,
  myPayroll = false,
  mySetting = false,
  dataBiowel,
  allCategory,
}: GetExternalUrlParams): string => {
  const newModules = ["Tesorería"];

  const currentCategory = allCategory.all_Categories?.find(
    cat => cat?.name === (category?.title || category?.name),
  );

  const params = new URLSearchParams({
    idModule: String(currentCategory?.id || ""),
    nameModule: currentCategory?.name || "",
    myPayroll: myPayroll ? "1" : "0",
    mySetting: mySetting ? "1" : "0",
    idUser: String(dataBiowel?.idUser || ""),
    idAccount: String(dataBiowel.idAccount || ""),
    idProfile: String(dataBiowel?.idProfile || ""),
    userImage: dataBiowel?.userImage || "",
    fullNameUser: dataBiowel.fullNameUser,
    token: dataBiowel.token,
  });

  const baseUrl = category?.url || "";
  const hasTrailingSlash = baseUrl.endsWith("/");
  const path = newModules.includes(currentCategory?.name || "") ? "" : "validate";

  return `${baseUrl}${hasTrailingSlash ? "" : "/"}${path}?${params.toString()}`;
};
