import { IFiltersPagination, IResponse } from "@app/common/types/http";
import { HTTP_DISPENSARY } from "@app/config/axios";
import { PendingConsult } from "./types";

export async function pendingConsultService(
  filters: { status: string } & IFiltersPagination,
  token: string,
) {
  const res = await HTTP_DISPENSARY(token).get<IResponse<PendingConsult[]>>(
    "/dispensaries/pending/",
    { params: filters },
  );
  return res.data;
}
