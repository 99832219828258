import { commonFilters, IFiltersPagination, IResponse } from "@app/common/types/http";
import {
  HTTP_ADMIN,
  HTTP_BASE,
  HTTP_DISPENSARY,
  HTTP_MEDICAL,
  HTTP_PAYROLL,
} from "@app/config/axios";
import {
  BankEntities,
  CompanyClientList,
  CompanyList,
  CompanyTypeList,
  ContractList,
  DepositType,
  DoctorList,
  Extensions,
  Franchise,
  PatientList,
  SiteList,
  StatusList,
} from "./types";

export async function companyTypeService(filters: commonFilters, token: string) {
  const res = await HTTP_BASE(token).get<IResponse<CompanyTypeList[]>>(
    "/medical/client/companytype/",
    { params: filters },
  );
  return res.data;
}
export async function listPatientService(
  filters: { orderByType?: string } & IFiltersPagination,
  token: string,
) {
  const res = await HTTP_BASE(token).get<IResponse<PatientList[]>>(
    "/medical/patient/listPatient/",
    { params: filters },
  );
  return res.data;
}
export async function patientInformationService(
  filters: { id?: string | number } & IFiltersPagination,
  token: string,
) {
  const res = await HTTP_BASE(token).get<IResponse<PatientList[]>>("/medical/patient/", {
    params: filters,
  });
  return res.data;
}
export async function companyClientService(
  filters: { contractCompanyType?: string } & commonFilters,
  token: string,
) {
  const res = await HTTP_BASE(token).get<IResponse<CompanyClientList[]>>("/medical/client/", {
    params: filters,
  });
  return res.data;
}
export async function listSitesService(
  filters: { modality?: string } & commonFilters,
  token: string,
) {
  const res = await HTTP_BASE(token).get<IResponse<SiteList[]>>("/medical/generals/site/", {
    params: filters,
  });
  return res.data;
}
export async function doctorListService(filters: commonFilters, token: string) {
  const res = await HTTP_BASE(token).get<IResponse<DoctorList[]>>("/medical/generals/medical/", {
    params: filters,
  });
  return res.data;
}
export async function statusListService(filters: { staFunctionality: string }, token: string) {
  const res = await HTTP_DISPENSARY(token).get<IResponse<StatusList[]>>("/status/", {
    params: filters,
  });
  return res.data;
}

export async function documentTypeService(token: string) {
  const res = await HTTP_PAYROLL(token).get<IResponse<StatusList[]>>("/document_types/");
  return res.data;
}

export async function companyListService(token: string, eaccount: number) {
  const res = await HTTP_MEDICAL(token).get<IResponse<CompanyList[]>>("/generals/company/", {
    params: { eaccount: eaccount },
  });
  return res.data;
}
export async function contractListService(
  filters: { slim: number; corporateClient: number },
  token: string,
) {
  const res = await HTTP_MEDICAL(token).get<IResponse<ContractList[]>>("/medicine/getContract/", {
    params: filters,
  });
  return res.data;
}
export async function extensionListService(token: string) {
  const res = await HTTP_DISPENSARY(token).get<IResponse<Extensions[]>>("/fileExtensions/");
  return res.data;
}

export async function listSitesToStartService(
  filters: { userId?: number } & commonFilters,
  token: string,
) {
  const res = await HTTP_BASE(token).get<IResponse<{ sites: SiteList[] }>>(
    "/medical/admissions/sites/",
    {
      params: filters,
    },
  );
  return res.data;
}

export async function bankListService(filters: { eaccount: number }, token: string) {
  const res = await HTTP_ADMIN(token).get<IResponse<BankEntities[]>>("/bankingEntities", {
    params: filters,
  });
  return res.data;
}
export async function depositTypeService(filters: { eaccount: number }, token: string) {
  const res = await HTTP_MEDICAL(token).get<IResponse<DepositType[]>>("/admissions/depositType/", {
    params: filters,
  });
  return res.data;
}
export async function franchiseService(filters: { eaccount: number }, token: string) {
  const res = await HTTP_BASE(token).get<IResponse<Franchise[]>>("/accounting/franchise/", {
    params: filters,
  });
  return res.data;
}
