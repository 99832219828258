import DownloadTemplate from "@app/common/components/DownloadTemplate";
import { useGetMedicationOrder } from "@app/services/deliveryAuth/hooks";
import { useLocation, useNavigate } from "react-router-dom";

const DeliveryOrderPDF = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const goBack = () => {
    navigate(-1);
  };

  const {
    data: pdfOrder = {
      results: "",
    },
  } = useGetMedicationOrder({
    id: location?.state?.data ?? null,
  });

  return (
    <DownloadTemplate
      goBackButton={() => {
        goBack();
      }}
      url={pdfOrder.results.replace(/^b'/gm, "").replace(/=*'$/gm, "") as string}
      urlType="base64"
      title="Detalle de fórmula de medicamentos"
    />
  );
};

export default DeliveryOrderPDF;
