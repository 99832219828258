import { Badge, Box, Button, Modal } from "@mantine/core";
import {
  IconCameraRotate,
  IconCapture,
  IconChecklist,
  IconPhotoCancel,
  IconTrash,
} from "@tabler/icons-react";
import { useCallback, useRef, useState } from "react";
import Webcam from "react-webcam";

const DeliveryRegisterMultiPicture = ({
  data,
  details,
  isOpen,
  onClose,
  setData,
  ...rest
}: {
  details?: boolean;
  stage?: string;
  data: string[];
  isOpen: boolean;
  setData: (data: string[]) => void;
  onClose: () => void;
}) => {
  const [isTakingPicture, setIsTakingPicture] = useState(false);

  const deleteEvidenceFunction = async (fileData: string) => {
    const filterToDelete = data.filter(file => file !== fileData);
    setData(filterToDelete);
  };

  return (
    <Modal
      {...rest}
      size={600}
      title="Tomar foto"
      opened={isOpen}
      onClose={() => {
        onClose();
        setIsTakingPicture(false);
      }}
    >
      <Box h={280} className="flex flex-column justify-center align-center">
        <Box className=" flex flex-column justify-center">
          {!isTakingPicture && (
            <div className=" evidenceContainer flex justify-center gap-3">
              {data && data?.length > 0 ? (
                data.map((file, ind) => {
                  return (
                    <div key={ind}>
                      <Box pos="relative">
                        <img
                          src={`data:application/pdf;base64,${file}`}
                          alt={"document"}
                          height={170}
                          width={250}
                        />
                        {!details && (
                          <div
                            data-testid="delete"
                            className="closeButtonEvidenceImage pointer"
                            onClick={() => deleteEvidenceFunction(file)}
                          >
                            <IconTrash />
                          </div>
                        )}
                      </Box>
                      <div className="flex justify-center">
                        <Badge variant="light" px={"sm"} color="#00b4cc" size="lg">
                          {ind === 0 ? "Cara frontal" : "Cara posterior"} de documento
                        </Badge>
                      </div>
                    </div>
                  );
                })
              ) : (
                <Box mb={"sm"} className="text-center flex justify-center w-100 ">
                  <span className="text-muted text-center flex ">
                    <IconPhotoCancel /> &nbsp; subir foto del documento
                  </span>
                </Box>
              )}
            </div>
          )}
        </Box>
        {data.length < 2 &&
          (isTakingPicture ||
            (!details && (
              <Box className="flex justify-center" py={"sm"} mt={"sm"}>
                <Button
                  variant="primary"
                  data-testid="takePicture"
                  onClick={() => setIsTakingPicture(true)}
                >
                  Tomar foto
                </Button>
              </Box>
            )))}
        {isTakingPicture && (
          <CustomWebcam setIsTakingAPicture={setIsTakingPicture} data={data} setData={setData} />
        )}
      </Box>
    </Modal>
  );
};

const CustomWebcam = ({
  setIsTakingAPicture,
  data,
  setData,
}: {
  setIsTakingAPicture: (data: boolean) => void;
  data: string[];
  setData: (data: string[]) => void;
}) => {
  const webcamRef = useRef<Webcam>(null);
  const [imgSrc, setImgSrc] = useState<string | null>(null);
  const [isDisabledButton, setIsDisabledButton] = useState(false);

  const capture = useCallback(() => {
    if (webcamRef.current !== null) {
      const imageSrc = webcamRef.current.getScreenshot();
      setImgSrc(imageSrc);
    }
  }, [webcamRef]);

  const retake = () => {
    setImgSrc(null);
  };

  const addEvidence = async () => {
    setIsDisabledButton(true);

    if (imgSrc) {
      setData([...data, imgSrc.replace(/^data:image\/[a-z]+;base64,/, "")]);
    }

    setIsTakingAPicture(false);
  };
  return (
    <div className="container flex flex-column justify-center items-center">
      {imgSrc ? (
        <div className="my-5">
          <img src={imgSrc} alt="webcam" />
        </div>
      ) : (
        <div className="flex justify-center">
          <Webcam height={225} width={300} ref={webcamRef} screenshotFormat="image/png" />
        </div>
      )}
      <Box className="flex justify-center" py={"xs"}>
        {imgSrc ? (
          <div className="flex justify-center mt-1 gap-2">
            <Button variant="outline-danger" className="" onClick={retake} data-testid="retake">
              <IconCameraRotate />
            </Button>{" "}
            &nbsp;
            <Button
              variant="outline"
              onClick={addEvidence}
              disabled={isDisabledButton}
              data-testid="save"
            >
              <IconChecklist />
            </Button>
          </div>
        ) : (
          <Button variant="outline" onClick={capture} mt={"sm"} data-testid="capture">
            <IconCapture />
          </Button>
        )}
      </Box>
    </div>
  );
};

export default DeliveryRegisterMultiPicture;
