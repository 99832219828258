import routes from "@app/config/routes";
import Features from "@app/features";
import ConsumerOutReport from "@app/features/consumerOutReport/ConsumerOutReport";
import DeliveryAuth from "@app/features/deliveryAuth/DeliveryAuth";
import DeliveryAuthDetail from "@app/features/deliveryAuth/DeliveryAuthDetail";
import DispensaryStore from "@app/features/dispensaryStore/dispensaryStore";
import DetailDispensarySupportFiles from "@app/features/dispensarySupportFiles/DetailDispensarySupportFiles";
import DispensarySupportFiles from "@app/features/dispensarySupportFiles/DispensarySupportFiles";
import Delivery from "@app/features/drugDelivery/delivery/Delivery";
import DeliveryOrderPDF from "@app/features/drugDelivery/delivery/components/DeliveryOrderPDF";
import DeliveryDetail from "@app/features/drugDelivery/delivery/detail/DeliveryDetail";
import Order from "@app/features/drugDelivery/order/Order";
import PatientOrderInformation from "@app/features/drugDelivery/order/patient/PatientOrderInformation";
import Selection from "@app/features/menu/Selection";
import PendingConsults from "@app/features/pendingConsults/PendingConsults";
import PharmacyControl from "@app/features/pharmacyControl/PharmacyControl";
import DetailSupportsConsults from "@app/features/supportsConsult/DetailSupportsConsults";
import SupportsConsult from "@app/features/supportsConsult/SupportsConsults";
import HandleUpdateSupportParameter from "@app/features/supportsParameters/HandleUpdateSupportParameter";
import SupportsParameters from "@app/features/supportsParameters/SupportsParameters";
import { createBrowserRouter } from "react-router-dom";
import Guardian from "./guardian";
import ValidateAuth from "./validate";

const router = createBrowserRouter([
  { path: routes.root, element: <Guardian /> },
  { path: routes.validate, element: <ValidateAuth /> },
  {
    path: routes.dispensation,
    element: <Features />,
    children: [
      {
        index: true,
        element: <Selection />,
      },
      {
        path: routes.drugDelivery,
        element: <Order />,
      },
      {
        path: routes.getEpsOrder,
        element: <Order />,
      },
      {
        path: routes.getDeliveryDispensation,
        element: <Delivery />,
      },
      {
        path: routes.getDeliveryDispensationDetail,
        element: <DeliveryDetail />,
      },
      {
        path: routes.getPatientOrderInformation,
        element: <PatientOrderInformation />,
      },
      {
        path: routes.getDeliveryAuth,
        element: <DeliveryAuth />,
      },
      {
        path: routes.getDeliveryAuthDetail,
        element: <DeliveryAuthDetail />,
      },
      {
        path: routes.getDeliveryDetailOrder,
        element: <DeliveryOrderPDF />,
      },
      {
        path: routes.getSupportsParameter,
        element: <SupportsParameters />,
      },
      {
        path: routes.getCreateSupportParameter,
        element: <HandleUpdateSupportParameter />,
      },
      {
        path: routes.getEditSupportParameter,
        element: <HandleUpdateSupportParameter />,
      },
      {
        path: routes.getSupportsConsult,
        element: <SupportsConsult />,
      },
      {
        path: routes.getSupportsConsultDetail,
        element: <DetailSupportsConsults />,
      },
      {
        path: routes.getDispensarySupportFiles,
        element: <DispensarySupportFiles />,
      },
      {
        path: routes.getDispensarySupportFilesDetail,
        element: <DetailDispensarySupportFiles />,
      },
      {
        path: routes.getDispensaryStore,
        element: <DispensaryStore />,
      },
      {
        path: routes.getPendingConsults,
        element: <PendingConsults />,
      },
      {
        path: routes.getPharmacyControl,
        element: <PharmacyControl />,
      },
      {
        path: routes.getPharmacyControlDetail,
        element: <Delivery />,
      },
      {
        path: routes.getConsumerOutReport,
        element: <ConsumerOutReport />,
      },
    ],
  },
]);

export default router;
