import { Badge, Box, Modal, NumberFormatter, Table, TextInput } from "@mantine/core";

import { warningNotification } from "@app/common/components/notifications";
import { useGetLotsScanner } from "@app/services/drugDelivery/hooks";
import { Lot, LotsScannerResponse, Medicine } from "@app/services/drugDelivery/types";
import { useDebouncedState } from "@mantine/hooks";
import { useEffect, useState } from "react";
import "../styles.scss";

export default function MedicationScanner({
  isOpen,
  onClose,
  orderId,
  handleChange,
  dataToValidate,
}: {
  orderId: number;
  isOpen: boolean;
  handleChange: (data: Lot[]) => void;
  dataToValidate: {
    dataOrder?: Medicine[];
    deliveryBy?: "insurance" | "freeSale" | "freeSaleWithOrder" | null;
    fullDeliveryIsApproved?: boolean;
  };
  onClose: () => void;
}) {
  const [barcode, setBarcode] = useDebouncedState<string | null>(null, 500);
  const [lotList, setLotList] = useState<LotsScannerResponse>({
    lots: [],
    id: "",
    barcode: "",
    description: "",
    manufacturer: "",
    salesPrice: "",
    medicationOrderId: "",
    OrderId: "",
    medicineId: "",
    medicineCUM: "",
    medicineInvima: "",
  });

  const {
    data: lotListRender = {
      results: {
        lots: [],
        id: "",
        barcode: "",
        description: "",
        manufacturer: "",
        salesPrice: "",
        medicationOrderId: "",
        OrderId: "",
        medicineId: "",
        medicineCUM: "",
        medicineInvima: "",
      },
      success: false,
    },
  } = useGetLotsScanner(
    {
      barcode: Number(barcode),
      orderId,
    },
    barcode !== null,
  );
  useEffect(() => {
    if (lotListRender && lotListRender?.results && lotListRender?.success) {
      setLotList(lotListRender.results);
    } else {
      setLotList({
        lots: [],
        id: "",
        barcode: "",
        description: "",
        manufacturer: "",
        salesPrice: "",
        medicationOrderId: "",
        OrderId: "",
        medicineId: "",
        medicineCUM: "",
        medicineInvima: "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lotListRender.results.lots?.length, lotListRender.success]);
  const qtyEstimated = () => {
    const selectedMedicine = dataToValidate.dataOrder?.find(med => med.id === lotList.medicineId);
    return selectedMedicine
      ? ((dataToValidate.fullDeliveryIsApproved
          ? selectedMedicine.limit
          : selectedMedicine.qtyEstimated) ?? 0)
      : 0;
  };

  const handleChangeQtyLots = (value: string, option: Lot, medicineId: number | string) => {
    const isInsurance = dataToValidate.deliveryBy === "insurance";
    const parsedValue = value === "" ? value : Number(value);

    // Actualización de lotes
    const resLot = lotList?.lots?.map(x =>
      x.id === option.id ? { ...x, qtyUsed: parsedValue, medicineId } : x,
    );

    if (!resLot) return;

    // Validación para "insurance"
    if ((isInsurance && qtyEstimated) || (isInsurance && dataToValidate.fullDeliveryIsApproved)) {
      const total = resLot.reduce((acc, item) => acc + Number(item.qtyUsed ?? 0), 0);

      if (total > qtyEstimated()) {
        return warningNotification(
          `No puede superar la entrega estimada: ${qtyEstimated()} und(s)`,
        );
      }
    }

    // Actualizar estado y ejecutar callback
    setLotList(state => ({ ...state, lots: resLot }));
    handleChange(resLot);
  };

  const renderRow = (option: Lot) => {
    const date = (option?.expirationDate ?? "").split("T")[0].replaceAll("/", "-");
    return (
      <Table.Tr key={`${option?.id}`}>
        <Table.Td>{lotList?.manufacturer}</Table.Td>
        <Table.Td>{lotList?.medicineCUM}</Table.Td>
        <Table.Td>{lotList?.medicineInvima}</Table.Td>
        <Table.Td>{option?.warehouseId}</Table.Td>
        <Table.Td ta={"center"}>{date}</Table.Td>
        <Table.Td ta={"center"}>{option?.qtyAvailable}</Table.Td>
        <Table.Td ta={"center"}>
          <NumberFormatter prefix="$ " value={lotList?.salesPrice} thousandSeparator />
        </Table.Td>
        <Table.Td ta={"center"} className="flex justify-center">
          <TextInput
            w={"50%"}
            type="number"
            key={option.id}
            data-testid={option.id}
            placeholder="Escribir..."
            value={option.qtyUsed}
            onChange={({ target }) => {
              handleChangeQtyLots(target.value, option, lotList.medicineId);
            }}
          />
        </Table.Td>
        <Table.Td ta={"start"}>{option.lotName}</Table.Td>
      </Table.Tr>
    );
  };

  const renderTable = () => {
    return (
      <Table className="biowel-table" withTableBorder key={lotList?.lots?.length}>
        <Table.Thead>
          <Table.Tr>
            <Table.Th ta={"start"} bg={"#fff"}>
              Lab
            </Table.Th>
            <Table.Th bg={"#fff"}>CUM</Table.Th>
            <Table.Th bg={"#fff"}>Invima</Table.Th>
            <Table.Th bg={"#fff"} ta={"start"}>
              Lote
            </Table.Th>
            <Table.Th bg={"#fff"} ta={"center"}>
              Vencimiento
            </Table.Th>
            <Table.Th bg={"#fff"} ta={"center"}>
              Stock
            </Table.Th>
            <Table.Th bg={"#fff"} ta={"center"}>
              Costo
            </Table.Th>
            <Table.Th bg={"#fff"} ta={"center"}>
              Cantidad
            </Table.Th>
            <Table.Th bg={"#fff"} ta={"start"}>
              Descripción
            </Table.Th>
          </Table.Tr>
        </Table.Thead>

        <Table.Tbody>
          {lotList?.lots?.length ? (
            lotList?.lots.map(renderRow)
          ) : (
            <Table.Tr>
              <Table.Td ta="center" colSpan={12}>
                No hay información para mostrar.
              </Table.Td>
            </Table.Tr>
          )}
        </Table.Tbody>
      </Table>
    );
  };

  const closeModal = () => {
    onClose();
    setBarcode(null);
    setLotList({
      lots: [],
      id: "",
      barcode: "",
      description: "",
      manufacturer: "",
      salesPrice: "",
      medicationOrderId: "",
      OrderId: "",
      medicineId: "",
      medicineCUM: "",
      medicineInvima: "",
    });
  };

  return (
    <Modal w="45%" size={1000} title="Escanear medicamentos" opened={isOpen} onClose={closeModal}>
      <Box className="flex  justify-between">
        <TextInput
          placeholder="Escribir..."
          label="Ingresar código"
          autoFocus
          w={"30%"}
          data-testid="medicineCode"
          // value={searchV}
          onChange={({ target }) => {
            setBarcode(target.value);
          }}
        />
        {lotListRender.results.lots &&
          lotListRender?.results?.lots?.length > 0 &&
          dataToValidate.deliveryBy === "insurance" && (
            <Badge variant="light" color="#00b4cc" className="self-end" p={"sm"}>
              Entrega estimada: {qtyEstimated() ?? 0}
            </Badge>
          )}
      </Box>
      <Box py={"sm"}>{renderTable()}</Box>
    </Modal>
  );
}
