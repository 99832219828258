import AppPagination from "@app/common/components/AppPagination";
import { Template } from "@app/common/components/Template";
import { TextField } from "@app/common/components/TextField";
import { IFiltersPagination } from "@app/common/types/http";
import { formattedDataSelect } from "@app/common/utils/utils";

import { useGetStatusList } from "@app/services/common/hooks";

import { warningNotification } from "@app/common/components/notifications";
import routes from "@app/config/routes";
import { useGetPendingConsult } from "@app/services/PendingConsult/hooks";
import { PendingConsult } from "@app/services/PendingConsult/types";
import { useDeliveryData } from "@app/store/useDeliverySearchData";
import { ActionIcon, Badge, Box, Collapse, Grid, Menu, Select, Table } from "@mantine/core";
import { useDebouncedState } from "@mantine/hooks";
import {
  IconBrandDeliveroo,
  IconChevronDown,
  IconChevronUp,
  IconCircleFilled,
  IconDotsVertical,
  IconEye,
  IconSearch,
} from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Fragment } from "react/jsx-runtime";
import DeliveryDetailDrawer from "./DeliveryDetailDrawer";

const PendingConsults = () => {
  const navigate = useNavigate();
  const { dataDispense, setDataDispense } = useDeliveryData(store => store);
  const [filters, setFilters] = useDebouncedState<IFiltersPagination & { status: string }>(
    {
      status: "",
      search: "",
      page: 1,
    },
    500,
  );

  const [pendingConsultList, setPendingConsultList] = useState<PendingConsult[]>([]);

  const {
    data: pendingConsultResults = {
      results: [],
      rowTotal: 0,
    },
    refetch,
  } = useGetPendingConsult({
    ...filters,
    perpage: 10,
  });

  const [FullDeliveryDrawer, setFullDeliveryDrawer] = useState({
    isOpen: false,
  });

  const {
    data: statusList = {
      results: [],
      rowTotal: 0,
    },
  } = useGetStatusList({
    staFunctionality: "dispensary",
  });

  useEffect(() => {
    if (pendingConsultResults.results.length > 0) {
      setPendingConsultList(pendingConsultResults.results.map(res => ({ ...res, isOpen: false })));
    }
  }, [pendingConsultResults.results]);

  const handleAccordion = (option: (typeof pendingConsultList)[number]) => {
    const temp = pendingConsultList.map(item =>
      item.disId === option.disId ? { ...item, isOpen: !item.isOpen } : item,
    );

    setPendingConsultList(temp);
  };

  const FiltersHeader = () => {
    return (
      <Grid align="flex-end" mb="lg" w="100%">
        <Grid.Col span={3}>
          <Select
            data-testid="status"
            label="Estado"
            searchable
            data={formattedDataSelect(statusList.results, "description", "id")}
            placeholder="Seleccionar..."
            onChange={value => {
              setFilters(state => ({
                ...state,
                status: value ?? "",
              }));
            }}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <TextField
            placeholder="Escribe aquí para buscar..."
            variant="filled"
            endIcon={<IconSearch className="pointer" onClick={() => refetch()} />}
            className="flex-grow-1 me-4"
            name="search"
            onChange={({ target }) =>
              setFilters(state => ({
                ...state,
                search: target.value,
              }))
            }
          />
        </Grid.Col>
      </Grid>
    );
  };

  const renderRow = (option: (typeof pendingConsultList)[number]) => {
    return (
      <Fragment key={option.disId}>
        <Table.Tr>
          <Table.Td
            ta={"start"}
            w={"15rem"}
            className="flex gap-2 justify-center align-center items-center"
          >
            {option.isOpen ? (
              <ActionIcon
                className="primary-color"
                variant="subtle"
                onClick={() => handleAccordion(option)}
              >
                <IconChevronUp />
              </ActionIcon>
            ) : (
              <ActionIcon
                className="primary-color"
                variant="subtle"
                onClick={() => handleAccordion(option)}
              >
                <IconChevronDown />
              </ActionIcon>
            )}
            <Box w={"100%"}>{option.patient.name}</Box>
          </Table.Td>

          <Table.Td ta={"center"}>
            {option.patient.documentType}
            {option.patient.document}
          </Table.Td>
          <Table.Td>{option.modality || "-"}</Table.Td>
          <Table.Td ta={"center"}>{option.patient.phone || "-"}</Table.Td>
          <Table.Td ta={"center"}>{option?.morDate?.split("T")[0] || "-"}</Table.Td>
          <Table.Td ta={"center"}>{option?.validity_date?.split("T")[0] || "-"}</Table.Td>
          <Table.Td>{option.orderedBy.name || "-"}</Table.Td>
          <Table.Td>
            {" "}
            <Badge variant="light" color={option.status.fontColor} w={"100%"}>
              {option.status.description}
            </Badge>
          </Table.Td>
          <Table.Td ta={"center"}>
            <Menu position="bottom-end" withArrow data-testid="menu">
              <Menu.Target>
                <ActionIcon variant="subtle">
                  <IconDotsVertical />
                </ActionIcon>
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Item
                  leftSection={<IconBrandDeliveroo />}
                  data-testid="editSupport"
                  onClick={() => {
                    setDataDispense({
                      ...dataDispense,
                      deliveryBy: "insurance",
                      orderId: option.morId,
                    });
                    navigate(routes.getDeliveryDispensation);
                  }}
                >
                  Entregar
                </Menu.Item>
                <Menu.Item
                  leftSection={
                    <IconCircleFilled
                      style={{
                        width: 10,
                      }}
                    />
                  }
                  data-testid="updateStatusSupport"
                  onClick={() => warningNotification(`En desarrollo`)}
                >
                  Ordenamiento
                </Menu.Item>
                <Menu.Item
                  leftSection={<IconEye />}
                  data-testid="editSupport"
                  onClick={() => {
                    setFullDeliveryDrawer(state => ({
                      ...state,
                      isOpen: true,
                    }));
                    warningNotification(`En desarrollo`);
                  }}
                >
                  Ver detalle
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Table.Td>
        </Table.Tr>

        <Table.Tr style={{ borderBottom: option.isOpen ? "1px solid #dee2e6" : 0 }}>
          <Table.Td py={0} colSpan={12}>
            <Collapse in={option.isOpen}>
              <Box px="45px" py={"sm"}>
                <table style={{ width: "60%", borderBottom: 0 }}>
                  <thead>
                    <tr className="primary-color">
                      <th align="left">Medicamento</th>
                      <th align="center">Descripción</th>
                      <th align="center">Cantidad</th>
                      <th align="center">Estado</th>
                    </tr>
                  </thead>
                  <tbody>
                    {option?.medicine?.length > 0 &&
                      option?.medicine?.map(medicine => {
                        return (
                          <tr key={medicine.id}>
                            <td>{medicine.name}</td>
                            <td align="center">{medicine.presentation.name}</td>
                            <td align="center">{medicine.qty_delivered}</td>
                            <td align="center">
                              {" "}
                              <Badge variant="light" color={option.status.fontColor} w={"100%"}>
                                {option.status.description}
                              </Badge>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </Box>
            </Collapse>
          </Table.Td>
        </Table.Tr>
      </Fragment>
    );
  };

  const renderTable = () => {
    return (
      <Table className="biowel-table">
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Paciente</Table.Th>
            <Table.Th ta={"center"}>Identificación</Table.Th>
            <Table.Th ta={"start"}>Modalidad</Table.Th>
            <Table.Th ta={"center"}>Teléfono</Table.Th>
            <Table.Th ta={"center"}>Fecha orden</Table.Th>
            <Table.Th ta={"center"}>Fecha validez</Table.Th>
            <Table.Th ta={"start"}>Ordenado por</Table.Th>
            <Table.Th ta={"center"}>Estado</Table.Th>
            <Table.Th></Table.Th>
          </Table.Tr>
        </Table.Thead>

        <Table.Tbody>
          {pendingConsultList?.length ? (
            pendingConsultList?.map(renderRow)
          ) : (
            <Table.Tr>
              <Table.Td ta="center" colSpan={12}>
                No hay información para mostrar.
              </Table.Td>
            </Table.Tr>
          )}
        </Table.Tbody>
      </Table>
    );
  };

  return (
    <Template title="Consultas pendientes">
      {FiltersHeader()}
      {renderTable()}
      <DeliveryDetailDrawer
        onClose={() => {
          setFullDeliveryDrawer(state => ({
            ...state,
            isOpen: false,
          }));
        }}
        isOpen={FullDeliveryDrawer.isOpen}
      />
      <AppPagination
        total={pendingConsultResults?.rowTotal || 0}
        value={filters.page || 1}
        onChange={value => setFilters(state => ({ ...state, page: value }))}
      />
    </Template>
  );
};

export default PendingConsults;
