import { GCidAccount } from "@app/config/globalsConst";
import { useGetSiteListToStart } from "@app/services/common/hooks";
import { useAuthentication } from "@app/store/useAuthtentication";
import { useGenericData } from "@app/store/useGenericData";
import { Badge, Card, Flex, Group, Text, Title } from "@mantine/core";
import { IconMapPin } from "@tabler/icons-react";
import "./styles.scss";

export default function SiteSelection() {
  const userId = useAuthentication(state => state.auth.dataBiowel.idUser);
  const setSite = useGenericData(state => state.setGenericData);
  const {
    data: sitesList = {
      results: { sites: [] },
      rowTotal: 0,
    },
  } = useGetSiteListToStart({
    eaccount: GCidAccount(),
    userId,
    status: 1,
  });

  const renderComponent = () => {
    return (
      <Flex
        h={"100%"}
        w={"100%"}
        gap="md"
        justify="center"
        align="center"
        direction="column"
        wrap="wrap"
        p={"xl"}
      >
        {sitesList?.results?.sites?.length > 0 ? (
          <div className="items-center self-center">
            <Title mb="sm" size={"h3"} c="text-gray" fw={"normal"} ta={"center"}>
              Selecciona la sede en la que te encuentras
            </Title>
            <Group p="xl" justify="space-around" key={sitesList?.results?.sites?.length}>
              {sitesList?.results?.sites.map(site => {
                return (
                  <Card
                    key={site.siteId}
                    shadow="sm"
                    radius="md"
                    __size="xl"
                    onClick={() =>
                      setSite({
                        siteId: site?.siteId,
                        siteName: site?.siteName ?? "",
                        siteResolutions: site?.resolutions ?? [],
                      })
                    }
                    className="cardSite"
                  >
                    <div className="flex-column justify-center items-center cardSpace">
                      <IconMapPin
                        style={{
                          width: 30,
                          height: 30,
                        }}
                        className="muted-color"
                      />
                      <Text size="sm" mt={"sm"} fw="inherit" className="">
                        {site?.siteName}
                      </Text>
                    </div>
                  </Card>
                );
              })}
            </Group>
          </div>
        ) : (
          <Badge variant="light" w={"80%"} p={"lg"} color="red" size="xl">
            No hay asignación de sedes
          </Badge>
        )}
      </Flex>
    );
  };

  return renderComponent();
}
