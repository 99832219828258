import * as MC from "@mantine/core";
import { IconChevronDown, IconChevronRight } from "@tabler/icons-react";
import clsx from "clsx";
import { NavLink } from "react-router-dom";

import { Fragment, useEffect, useState } from "react";

import { getEnvs } from "../utils/getEnvs";

import { Functionality } from "@app/services/category/types";
import { useAuthentication } from "@app/store/useAuthtentication";
import { useGenericData } from "@app/store/useGenericData";
import { getExternalUrl } from "@app/utils/getExternalUrl";
import { historyRedirect } from "@app/utils/historyRedirect";
import { moduleInfo } from "../utils/utils";
import "./styles.scss";

const env = getEnvs();

interface ISidebarLinkProps {
  icon: string;
  label: string;
  links: Functionality[];
  onClick: () => void | null;
  isSidebarClosed?: boolean;
  onCloseSidebar: () => void;
}

export default function SidebarLink(props: ISidebarLinkProps) {
  const [isOpen, setIsOpen] = useState(false);
  const { dataBiowel, allCategory } = useAuthentication(store => store.auth);
  const { siteId, siteName } = useGenericData(store => store.genericData);

  useEffect(() => {
    if (props.isSidebarClosed) {
      setIsOpen(false);
    }
  }, [props.isSidebarClosed]);

  const onClickLink = () => {
    if (props.onClick) {
      props.onClick();
    }
    setIsOpen(state => !state);
  };

  const onNavigateExternalUrl = (url: string) => {
    window.location.href = `${env.URL_PROTOCOL}${env.URL_BASE}${url}`;
  };

  const renderSubLinks = () => {
    return props.links.map(elem => (
      <Fragment key={elem.id}>
        {elem.url.includes("/dispensation") ? (
          <NavLink
            to={elem.url}
            className={({ isActive }) => clsx("sidebar-sublink", { isActive })}
            onClick={() => props.onCloseSidebar()}
          >
            {elem.name}
          </NavLink>
        ) : elem.isExternal ? (
          <div
            className={"sidebar-sublink"}
            onClick={e => {
              e.preventDefault();

              historyRedirect({
                path: `${getExternalUrl({
                  allCategory,
                  dataBiowel,
                  category: moduleInfo(elem.url, allCategory),
                })}&goTo=${elem.path}&params=${encodeURIComponent(
                  JSON.stringify({
                    siteId,
                    siteName,
                  }),
                )}`,
                isInternal: false,
                useQueryParams: true,
              });

              props.onCloseSidebar();
            }}
          >
            {elem.name}
          </div>
        ) : (
          <div className="sidebar-sublink" onClick={() => onNavigateExternalUrl(elem.url)}>
            {elem.name}
          </div>
        )}
      </Fragment>
    ));
  };

  const renderComponent = () => {
    return (
      <>
        <MC.UnstyledButton className="sidebar-link" onClick={onClickLink}>
          <MC.Flex align="center" flex={1}>
            <MC.Center w={80}>
              <MC.Image src={props.icon} w={30} h={30} fit="contain" />
            </MC.Center>
            <MC.Text fz="lg">{props.label}</MC.Text>
          </MC.Flex>
          {!isOpen ? <IconChevronRight /> : <IconChevronDown />}
        </MC.UnstyledButton>
        {props.links.length > 0 ? (
          <MC.Collapse in={isOpen} style={{ whiteSpace: "nowrap" }}>
            {renderSubLinks()}
          </MC.Collapse>
        ) : null}
      </>
    );
  };

  return renderComponent();
}
