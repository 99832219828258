import { IFiltersPagination } from "@app/common/types/http";
import { useAuthentication } from "@app/store/useAuthtentication";
import { useQuery } from "@tanstack/react-query";
import { pendingConsultService } from "./pendingConsult";

export function useGetPendingConsult(
  filters: { status: string } & IFiltersPagination,
  enabled = true,
) {
  const token = useAuthentication(state => state.auth.dataBiowel.token);

  const query = useQuery({
    enabled,
    gcTime: 0,
    queryKey: ["supportParams", filters.status, filters.page, filters.search],
    queryFn: async () => await pendingConsultService(filters, token),
  });

  return query;
}
