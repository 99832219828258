import { ISiteResolution } from "@app/services/common/types";
import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

export interface IDataGeneric {
  siteId: number | null;
  siteName: string;
  siteResolutions: ISiteResolution[];
  accountName?: string;
}

interface GenericData {
  genericData: IDataGeneric;
  setGenericData: (payload: IDataGeneric) => void;
  clearGenericData: () => void;
}

const initialState: IDataGeneric = {
  siteId: null,
  siteName: "",
  siteResolutions: [],
  accountName: "",
};

export const useGenericData = create<GenericData>()(
  devtools(
    persist(
      set => ({
        genericData: initialState,
        setGenericData: payload => {
          set(state => ({ ...state, genericData: payload }));
        },
        clearGenericData: () => {
          set(state => ({ ...state, genericData: initialState }));
        },
      }),
      {
        name: "data-generic-storage",
      },
    ),
  ),
);
