import * as MC from "@mantine/core";
import * as TI from "@tabler/icons-react";

import { useDisclosure } from "@mantine/hooks";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useGetAccounts } from "@app/services/account";
import { useAuthentication } from "@app/store/useAuthtentication";

import { getEnvs } from "@app/common/utils/getEnvs";
import routes from "@app/config/routes";

import BiowelLogo from "@app/assets/logoBiowel.svg";
import TtnLogo from "@app/assets/logo_ttn.png";

import { useDeliveryData } from "@app/store/useDeliverySearchData";
import { useGenericData } from "@app/store/useGenericData";
import ChangeAccount from "./ChangeAccount";
import "./styles.scss";

const env = getEnvs();

export default function Header() {
  const { toggleColorScheme } = MC.useMantineColorScheme();
  const navigate = useNavigate();

  const userData = useAuthentication(state => state.auth.dataBiowel);
  const { categories, allCategory } = useAuthentication(state => state.auth);
  const clearAuthentication = useAuthentication(state => state.clearAuthentication);
  const createAuthentication = useAuthentication(state => state.createAuthentication);
  const { siteName, siteId } = useGenericData(store => store.genericData);

  const [changeAccountOpened, changeAccountHandlers] = useDisclosure();
  const [helpOpened, helpHandlers] = useDisclosure();
  const [counter, setCounter] = useState(0);

  const { setGenericData, genericData, clearGenericData } = useGenericData(state => state);
  const { clearDataDispense } = useDeliveryData(state => state);

  const { data: accountsData } = useGetAccounts(userData.idUser);

  const currentAccount = useMemo(() => {
    if (accountsData?.accounts) {
      const current = accountsData.accounts.find(acc => acc.id === userData.idAccount);
      setGenericData({ ...genericData, accountName: current?.name });
      return {
        ...current,
        profile: current?.profile.find(prof => prof.id === userData.idProfile),
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountsData?.accounts, userData.idAccount, userData.idProfile]);

  const onChangeColorScheme = () => {
    setCounter(state => state + 1);

    if (counter === 4) {
      toggleColorScheme();
      setCounter(0);
    }
  };

  const logoutSession = () => {
    clearAuthentication();
  };

  const onChangeAccount = (accountId: number, profileId: number) => {
    createAuthentication({
      categories: categories,
      allCategory,
      dataBiowel: {
        ...userData,
        idAccount: accountId,
        idProfile: profileId,
      },
    });
    clearDataDispense();
    clearGenericData();
    navigate(routes.dispensation);
  };

  const renderMenu = () => {
    return (
      <MC.Menu width={215} position="bottom-end">
        <MC.Menu.Target>
          <MC.Box className="app-header__user">
            <TI.IconBuilding style={{ width: 25, height: 25 }} className="primary-color" />
            <MC.Text fz="lg" ml="xs" flex={1} fw="inherit">
              {currentAccount?.name}
            </MC.Text>
            <MC.Avatar size={38} src={userData.userImage} className="outline-secondary" />
          </MC.Box>
        </MC.Menu.Target>

        <MC.Menu.Dropdown>
          <MC.Stack px="md" py="sm" align="center" gap={2}>
            <MC.Avatar
              size="lg"
              src={userData.userImage}
              mb="sm"
              onClick={onChangeColorScheme}
              className="outline-secondary"
            />
            <MC.Text fw="bold">{userData.fullNameUser}</MC.Text>
            <MC.Text ta="center" style={{ whiteSpace: "wrap" }} fw="bold" c="gray.4">
              {currentAccount?.name} - {currentAccount?.profile?.nombre}
            </MC.Text>
          </MC.Stack>

          <MC.Menu.Item onClick={changeAccountHandlers.open}>Cambiar Rol y Cuenta</MC.Menu.Item>
          <MC.Menu.Item
            component="a"
            href={`${env.URL_PROTOCOL}${env.URL_BASE}/miNomina/miNomina/hojaDeVida`}
          >
            Mi nómina
          </MC.Menu.Item>
          <MC.Menu.Item
            component="a"
            href={`${env.URL_PROTOCOL}${env.URL_BASE}/configuracion/miperfil`}
          >
            Configuración
          </MC.Menu.Item>
          <MC.Menu.Item onClick={helpHandlers.open}>Ayuda</MC.Menu.Item>
          <MC.Menu.Item
            component="a"
            href={`${env.URL_PROTOCOL}${env.URL_BASE}/logout/${userData.idUser}`}
            color="red"
            onClick={logoutSession}
          >
            Cerrar sesión
          </MC.Menu.Item>
        </MC.Menu.Dropdown>
      </MC.Menu>
    );
  };

  const renderHelpModal = () => {
    return (
      <MC.Modal title="Ayuda" opened={helpOpened} onClose={helpHandlers.close}>
        <MC.Stack mb="lg" align="center">
          <MC.Image src={TtnLogo} w={126} h={44} />
          <MC.Text fz="lg" fw="bold">
            Información de Contacto
          </MC.Text>
          <MC.Stack>
            <MC.Group>
              <TI.IconPhone className="primary-color small-icon" />
              <MC.Text fz="xs">+57 300 658 32 65</MC.Text>
            </MC.Group>
            <MC.Group>
              <TI.IconMail className="primary-color small-icon" />
              <MC.Text fz="xs">analistadesoporte@ttncompany.com</MC.Text>
            </MC.Group>
            <MC.Group>
              <TI.IconMapPin className="primary-color small-icon" />
              <MC.Text fz="xs">Carrera 51b # 87 - 50 Piso 3 Local 337</MC.Text>
            </MC.Group>
          </MC.Stack>
        </MC.Stack>
        <MC.Group justify="end" p="lg">
          <MC.Button onClick={helpHandlers.close} size="sm" px="xl" color="brand-color">
            Aceptar
          </MC.Button>
        </MC.Group>
      </MC.Modal>
    );
  };

  const renderComponent = () => {
    return (
      <MC.Box className="app-header ">
        <MC.Group justify="space-between">
          <MC.UnstyledButton
            onClick={() => {
              clearDataDispense();
              clearGenericData();
            }}
            component="a"
            href={`${env.URL_PROTOCOL}${env.URL_BASE}/categoryselect`}
          >
            <MC.Image w={150} draggable={false} src={BiowelLogo} />
          </MC.UnstyledButton>
          <MC.Title order={3} c="gray.4" fw="inherit">
            • Dispensación
          </MC.Title>
        </MC.Group>

        <div className="flex gap-3">
          {siteId && (
            <MC.Box className="flex gap-2 items-center">
              <TI.IconMapPinFilled
                style={{
                  width: 30,
                  height: 30,
                }}
                className="muted-color"
              />
              <MC.Text ta={"center"} size="sm" fw="inherit" className="">
                {siteName}
              </MC.Text>
            </MC.Box>
          )}
          {renderMenu()}
        </div>
        {renderHelpModal()}
        <ChangeAccount
          isOpen={changeAccountOpened}
          onClose={changeAccountHandlers.close}
          accounts={accountsData?.accounts}
          onSelectAccount={onChangeAccount}
        />
      </MC.Box>
    );
  };

  return renderComponent();
}
