import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

import type { AllCategory, CategoriesResponse } from "@app/services/category/types";
import type { IAuthData } from "./types";

interface AuthData {
  auth: IAuthData;
  createAuthentication: (_payload: IAuthData) => void;
  setCategories: (_payload: AllCategory[]) => void;
  setAllCategories: (_payload: CategoriesResponse) => void;
  clearAuthentication: () => void;
}

const initialState: IAuthData = {
  dataBiowel: {
    idModule: 0,
    idUser: 0,
    idAccount: 0,
    idProfile: 0,
    token: "",
    userImage: "",
    fullNameUser: "",
    myPayroll: "",
    mySetting: "",
    nameModule: "",
  },
  categories: [],
  allCategory: {
    all_Categories: [],
    new_category: [],
  },
};

/**
 * Zustand hook for authentication management
 */
export const useAuthentication = create<AuthData>()(
  devtools(
    persist(
      set => ({
        auth: initialState,
        createAuthentication: payload => {
          set(state => ({ ...state, auth: payload }));
        },
        setCategories: payload => {
          set(state => ({
            ...state,
            auth: {
              ...state.auth,
              categories: payload,
            },
          }));
        },
        setAllCategories: payload => {
          set(state => ({
            ...state,
            auth: {
              ...state.auth,
              allCategory: payload,
            },
          }));
        },
        clearAuthentication: () => {
          set(state => ({ ...state, auth: initialState }));
        },
      }),
      {
        name: "data-biowel-storage",
      },
    ),
  ),
);
