import { IFiltersPagination, IResponse } from "@app/common/types/http";
import { HTTP_DISPENSARY } from "@app/config/axios";
import { pharmacyControlFilter, pharmacyControlResponse } from "./types";

export async function pharmacyControlService(
  filters: pharmacyControlFilter & IFiltersPagination,
  token: string,
) {
  const res = await HTTP_DISPENSARY(token).get<IResponse<pharmacyControlResponse[]>>(
    "/dispensaries/complete/",
    { params: filters },
  );
  return res.data;
}
