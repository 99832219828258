/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { IFiltersPagination } from "@app/common/types/http";
import { useAuthentication } from "@app/store/useAuthtentication";
import { useQuery } from "@tanstack/react-query";
import { pharmacyControlService } from "./supportsConsult";
import { pharmacyControlFilter } from "./types";

export function useGetPharmacyControlList(
  filters: pharmacyControlFilter & IFiltersPagination,
  enabled = true,
) {
  const token = useAuthentication(state => state.auth.dataBiowel.token);
  const query = useQuery({
    enabled,
    gcTime: 0,
    queryKey: [
      "supportParams",
      filters.modality,
      filters.consumptionCenterId,
      filters.site,
      filters.startDate,
      filters.endDate,
      filters.search,
      filters.status,
      filters.page,
    ],
    queryFn: async () => await pharmacyControlService(filters, token),
  });

  return query;
}
