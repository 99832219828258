import { Portal } from "@mantine/core";
import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";

import { useLoading } from "@app/common/hooks/useLoading";
import { useGetCategories } from "@app/services/category";
import { useAuthentication } from "@app/store/useAuthtentication";

import AppLoader from "@app/common/components/AppLoader";
import Header from "@app/common/components/Header";
import Sidebar from "@app/common/components/Sidebar";

import { AllCategory, CategoriesResponse } from "@app/services/category/types";
import { useGenericData } from "@app/store/useGenericData";
import SiteSelection from "./menu/SiteSelection";
import "./styles.scss";

export default function Features() {
  const isLoading = useLoading();
  const location = useLocation();
  const { siteId } = useGenericData(state => state.genericData);
  const { idProfile, idModule } = useAuthentication(state => state.auth.dataBiowel);

  const { setCategories, setAllCategories } = useAuthentication(state => state);

  const categoriesQuery = useGetCategories({
    id_profile: idProfile,
  });

  const categoryModule = [
    categoriesQuery.data?.all_Categories.find(mod => mod.id === idModule),
  ] as AllCategory[];

  const applyClass = () => {
    const excludedPaths = [
      "/dispensation/drugDelivery",
      "/dispensation/drugDelivery/dispensary/detail",
      "/dispensation/drugDelivery/eps/order",
      "/dispensation/drugDelivery/patientOrderInformation",
      "/dispensation/drugDelivery/dispensary/detailOrder",
      "/dispensation/deliveryAuth/detail",
      "/dispensation/supportParameters/create",
      "/dispensation/supportParameters/edit",
      "/dispensation/store",
    ];

    return !excludedPaths.some(path => location.pathname === path);
  };

  useEffect(() => {
    if (categoriesQuery.data) {
      if (categoryModule) {
        setCategories(categoryModule);
      }
      setAllCategories(categoriesQuery.data as CategoriesResponse);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoriesQuery.data, setCategories, setAllCategories]);

  const renderComponent = () => {
    return (
      <>
        {isLoading && (
          <Portal target="#app-loader">
            <AppLoader />
          </Portal>
        )}
        <div className="app-container">
          <Header />

          {siteId ? (
            <div className="app-viewport">
              <Sidebar />
              <main
                id="mainContainer"
                className={`${applyClass() ? "main-container" : "main-container-noP"}`}
              >
                <Outlet />
              </main>
            </div>
          ) : (
            <div className="app-viewport">
              <main
                id="mainContainer"
                className={`${applyClass() ? "main-container" : "main-container-noP"}`}
              >
                <SiteSelection />
              </main>
            </div>
          )}
        </div>
      </>
    );
  };

  return renderComponent();
}
