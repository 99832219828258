import { warningNotification } from "@app/common/components/notifications";
import SignatureComponent from "@app/common/components/SignatureComponent";
import { Template } from "@app/common/components/Template";
import { formattedDataSelect, mergeArrays } from "@app/common/utils/utils";
import routes from "@app/config/routes";
import { Carousel } from "@mantine/carousel";
import "@mantine/carousel/styles.css";

import { useGetDocumentList, useGetOrderDetail } from "@app/services/drugDelivery/hooks";
import {
  FileAdditionalSupports,
  Lot,
  Medicine,
  OrderListResponse,
} from "@app/services/drugDelivery/types";
import { useDeliveryData } from "@app/store/useDeliverySearchData";
import { calculateAge } from "@app/utils/generateId";
import {
  Badge,
  Box,
  Button,
  Card,
  Grid,
  Select,
  Table,
  Text,
  TextInput,
  Tooltip,
} from "@mantine/core";
import { useIsomorphicEffect } from "@mantine/hooks";
import {
  IconArrowLeft,
  IconArrowRight,
  IconCamera,
  IconEye,
  IconUpload,
} from "@tabler/icons-react";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import classes from "./CarouselStyles.module.css";
import FullDelivery from "./components/CompleteDeliveryRequestForm";
import DeliveryRegisterMultiPicture from "./components/DeliveryRegisterMultiPicture";
import DeliveryRegisterPicture from "./components/DeliveryRegisterPicture";
import DeliverySupportModal from "./components/DeliverySupportModal";
import MedicationScanner from "./components/MedicationScanner";
import DeliveryFormToSend from "./DeliveryFormToSend";
import "./styles.scss";
const Delivery = () => {
  const navigate = useNavigate();
  const {
    orderId,
    action,
    deliveryBy,
    isPendingCompleteDelivery,
    dataPatient,
    fullDeliveryIsApproved,
  } = useDeliveryData(store => store.dataDispense);

  const setDataDispense = useDeliveryData(store => store.setDataDispense);
  const disabledForm = Boolean(action === "detail");
  const showDetail = Boolean(action === "detail");
  const isFreeSale = Boolean(deliveryBy === "freeSale");
  const isFreeSaleWithOrder = Boolean(deliveryBy === "freeSaleWithOrder");
  const [data, setData] = useState<OrderListResponse>();
  const [FullDeliveryDrawer, setFullDeliveryDrawer] = useState({
    isOpen: false,
  });
  const [deliveryDrawer, setDeliveryDrawer] = useState({
    isOpen: false,
  });
  const [supportFilesModal, setSupportFilesModal] = useState<{
    isOpen: boolean;
    data: FileAdditionalSupports[];
  }>({
    isOpen: false,
    data: [],
  });

  const [imagesModal, setImagesModal] = useState<{
    isOpenFaceModal: boolean;
    isOpenDocumentModal: boolean;
  }>({
    isOpenFaceModal: false,
    isOpenDocumentModal: false,
  });

  const [lotsMedication, setLotsMedication] = useState<{
    isOpen: boolean;
    data: { qtyUsed: number; id: number; medicineId: number }[];
  }>({
    isOpen: false,
    data: [],
  });

  const { data: orderDetail, refetch } = useGetOrderDetail(
    {
      orderId: Number(orderId),
    },
    orderId !== null,
  );

  const {
    data: documentListRes = {
      results: [],
      rowTotal: 0,
    },
  } = useGetDocumentList();

  type IOptionItem = (typeof documentList)[number] & { prefix?: string };

  const documentList = useMemo(() => {
    if (documentListRes?.results) {
      return [...formattedDataSelect(documentListRes.results, "description", "id", ["prefix"])];
    }
    return [];
  }, [documentListRes?.results]);

  const renderRow = (option: Medicine) => {
    const filtered = lotsMedication.data.filter(d => d.medicineId === option.id);
    const total = filtered.reduce((acc, item) => {
      return acc + item.qtyUsed;
    }, 0);
    return (
      <Table.Tr key={option.id}>
        <Table.Td>{option.id}</Table.Td>
        <Table.Td>{option.name}</Table.Td>
        <Table.Td ta={"center"}>{option.qtyStock ?? "-"}</Table.Td>
        <Table.Td ta={"center"}>{option.qtyOrder ?? "-"}</Table.Td>
        <Table.Td ta={"center"}>{option.qtyDispensed ?? "-"}</Table.Td>
        <Table.Td ta={"center"}>{option.qtyEstimated ?? "-"}</Table.Td>
        <Table.Td ta={"center"}>{total}</Table.Td>
        <Table.Td ta={"center"}>{option.qtyRemaining ?? "-"}</Table.Td>
      </Table.Tr>
    );
  };

  const renderTable = () => {
    return (
      <Table mt={"sm"} mb="sm" className="biowel-table">
        <Table.Thead>
          <Table.Tr>
            <Table.Th ta={"start"}>Código</Table.Th>
            <Table.Th>Medicamento</Table.Th>
            <Table.Th ta={"center"}>Stock disp.</Table.Th>
            <Table.Th ta={"center"}>Ordenado</Table.Th>
            <Table.Th ta={"center"}>Entrega anterior</Table.Th>
            <Table.Th ta={"center"}>Estimado actual</Table.Th>
            <Table.Th ta={"center"}>Entrega real</Table.Th>
            <Table.Th ta={"center"}>Postfechado</Table.Th>
          </Table.Tr>
        </Table.Thead>

        <Table.Tbody>
          {orderDetail?.results?.medicines?.length ? (
            orderDetail?.results?.medicines?.map(renderRow)
          ) : (
            <Table.Tr>
              <Table.Td ta="center" colSpan={12}>
                No hay información para mostrar.
              </Table.Td>
            </Table.Tr>
          )}
        </Table.Tbody>
      </Table>
    );
  };

  const renderRowFreeSale = (option: Medicine) => {
    const filtered = lotsMedication.data.filter(d => d.medicineId === option.id);
    const total = filtered.reduce((acc, item) => {
      return acc + item.qtyUsed;
    }, 0);
    return (
      <Table.Tr key={option.id}>
        <Table.Td bg={"#00B4CC10"}>{option.id}</Table.Td>
        <Table.Td bg={"#00B4CC10"}>{option.name}</Table.Td>
        <Table.Td bg={"#00B4CC10"} ta={"center"}>
          {option.qtyStock ?? "-"}
        </Table.Td>
        <Table.Td ta={"center"}>{total}</Table.Td>
      </Table.Tr>
    );
  };

  const renderTableFreeSale = () => {
    return (
      <Table mt={"sm"} mb="sm" className="biowel-table">
        <Table.Thead>
          <Table.Tr>
            <Table.Th ta={"start"}>Código</Table.Th>
            <Table.Th>Medicamento</Table.Th>
            <Table.Th ta={"center"}>Stock disponible</Table.Th>
            <Table.Th ta={"center"}>Entrega real</Table.Th>
          </Table.Tr>
        </Table.Thead>

        <Table.Tbody>
          {orderDetail?.results?.medicines?.length ? (
            orderDetail?.results?.medicines?.map(renderRowFreeSale)
          ) : (
            <Table.Tr>
              <Table.Td ta="center" colSpan={12}>
                No hay información para mostrar.
              </Table.Td>
            </Table.Tr>
          )}
        </Table.Tbody>
      </Table>
    );
  };

  const onCloseLotsModal = () => {
    setLotsMedication(state => ({ ...state, isOpen: false }));
  };

  const handleChangeCompanion = (value: string) => {
    setData(state => ({
      ...state,
      patientSignature: value,
    }));
  };

  const handlePictureFace = (file: string) => {
    setData(state => ({
      ...state,
      patientFacePicture: file,
    }));
  };
  const handlePictureDocument = (files: string[]) => {
    setData(state => ({
      ...state,
      patientDocumentPictures: files,
    }));
  };

  const handleChangeQtyLots = (lots: Lot[]) => {
    const filtered = lots.filter(l => l.qtyUsed) ?? [];
    const datas = filtered.map(lot => ({
      id: lot.id,
      qtyUsed: Number(lot.qtyUsed),
      medicineId: Number(lot.medicineId),
    }));
    setLotsMedication(state => ({
      ...state,
      data: mergeArrays(state.data, datas, "array1"),
    }));

    const tempMeds = orderDetail?.results?.medicines?.map(med => {
      const filteredA = mergeArrays(lotsMedication.data, datas, "array1").filter(
        d => d.medicineId === med.id,
      );

      const total = filteredA.reduce((acc, item) => {
        return Number(acc) + Number(item.qtyUsed);
      }, 0);

      return {
        id: med.id,
        qtyDelivered: med.qtyDelivered, //entregado
        qtyDispensed: total,
        qtyOrder: med.qtyOrder,
        presentationId: med.presentationId,
        description: med.description,
        name: med.name,
      };
    }) as Medicine[];

    if (tempMeds) {
      setData(state => ({
        ...state,
        orderInfo: orderDetail?.results.orderInfo,
        medicines: tempMeds,
      }));
    }
  };

  const handleSupportFiles = (files: FileAdditionalSupports[]) => {
    setSupportFilesModal(state => ({ ...state, data: files }));
    setData(state => ({
      ...state,
      additionalSupports: files,
    }));
  };

  const goBack = () => {
    navigate(-1);
    setDataDispense({ showDeliveryBy: false, orderId: null });
  };

  const onSubmitValidations = () => {
    if (!data?.additionalSupports?.some(x => x.file !== "")) {
      warningNotification("Los soportes son obligatorios", "Intenta de nuevo");
      return;
    } else if (
      data?.disRequestByDocumentType === "" ||
      data?.disRequestByDocumentType === undefined
    ) {
      warningNotification("El tipo de documento es obligatorio", "Intenta de nuevo");
      return;
    } else if (data?.receiveName === "" || data?.receiveName === undefined) {
      warningNotification("El nombre de quien recibe es obligatorio", "Intenta de nuevo");
      return;
    } else if (data?.receiveDocument === "" || data?.receiveDocument === undefined) {
      warningNotification("El No de identificación es obligatorio", "Intenta de nuevo");
      return;
    } else if (data?.patientSignature === "" || data?.patientSignature === undefined) {
      warningNotification("La firma es obligatoria", "Intenta de nuevo");
      return;
    } else if (data?.patientFacePicture === "" || data?.patientFacePicture === undefined) {
      warningNotification("La fotografía del rostro es obligatorio", "Intenta de nuevo");
      return;
    } else if (
      (data?.patientDocumentPictures?.length ?? 0) !== 2 ||
      data?.patientDocumentPictures === undefined
    ) {
      warningNotification(
        "Es necesario enviar la fotografía frontal y posterior del documento",
        "Intenta de nuevo",
      );
      return;
    } else if (!data?.medicines?.some(x => x.qtyDispensed !== 0)) {
      warningNotification("La entrega real es obligatoria", "Intenta de nuevo");
      return;
    } else {
      setDeliveryDrawer(state => ({
        ...state,
        isOpen: true,
      }));
    }
  };

  const clientInformationFreeSale = () => (
    <Card mb={"md"} padding="lg" radius="md" __size="xl" className="">
      <Grid>
        <Grid.Col span={2.4}>
          <Text fz="xs" ml="xs" flex={1} fw={"inherit"}>
            Nombres
          </Text>
          <Text fz="xs" ml="xs" flex={1} c="secondary-color" fw={"bold"}>
            {dataPatient?.first_name} {dataPatient?.other_names}
          </Text>
        </Grid.Col>
        <Grid.Col span={2.4}>
          <Text fz="xs" ml="xs" flex={1} fw={"inherit"}>
            Apellidos
          </Text>
          <Text fz="xs" ml="xs" flex={1} c="secondary-color" fw={"bold"}>
            {dataPatient?.last_name} {dataPatient?.second_surname}
          </Text>
        </Grid.Col>
        <Grid.Col span={2.4}>
          <Text fz="xs" ml="xs" flex={1} fw={"inherit"}>
            Identificación
          </Text>
          <Text fz="xs" ml="xs" flex={1} c="secondary-color" fw={"bold"}>
            {dataPatient?.docTypeDesc}
            {dataPatient?.document}
          </Text>
        </Grid.Col>

        <Grid.Col span={2.4}>
          <Text fz="xs" ml="xs" flex={1} fw={"inherit"}>
            Email
          </Text>
          <Text fz="xs" ml="xs" flex={1} c="secondary-color" fw={"bold"}>
            {dataPatient?.email}
          </Text>
        </Grid.Col>
        <Grid.Col span={2.4}>
          <Text fz="xs" ml="xs" flex={1} fw={"inherit"}>
            Teléfono
          </Text>
          <Text fz="xs" ml="xs" flex={1} c="secondary-color" fw={"bold"}>
            {dataPatient?.whatsapp}
          </Text>
        </Grid.Col>
      </Grid>
    </Card>
  );
  const clientInformation = () => (
    <Card mb={"md"} padding="lg" radius="md" __size="xl" className="">
      <Grid>
        <Grid.Col span={2.4}>
          <Text fz="xs" ml="xs" flex={1} fw={"inherit"}>
            Paciente
          </Text>
          <Text fz="xs" ml="xs" flex={1} c="secondary-color" fw={"bold"}>
            {orderDetail?.results?.orderInfo?.patient.name}
          </Text>
        </Grid.Col>
        <Grid.Col span={2.4}>
          <Text fz="xs" ml="xs" flex={1} fw={"inherit"}>
            Identificación
          </Text>
          <Text fz="xs" ml="xs" flex={1} c="secondary-color" fw={"bold"}>
            {orderDetail?.results?.orderInfo?.patient?.documentType}
            {orderDetail?.results?.orderInfo?.patient?.document}
          </Text>
        </Grid.Col>
        <Grid.Col span={2.4}>
          <Text fz="xs" ml="xs" flex={1} fw={"inherit"}>
            Edad
          </Text>
          <Text fz="xs" ml="xs" flex={1} c="secondary-color" fw={"bold"}>
            {calculateAge(orderDetail?.results?.orderInfo?.patient?.birthDate ?? "")} años
          </Text>
        </Grid.Col>
        <Grid.Col span={2.4}>
          <Text fz="xs" ml="xs" flex={1} fw={"inherit"}>
            Aseguradora
          </Text>
          <Text fz="xs" ml="xs" flex={1} c="secondary-color" fw={"bold"}>
            {orderDetail?.results?.orderInfo?.corporateClient?.name}
          </Text>
        </Grid.Col>
        <Grid.Col span={2.4}>
          <Text fz="xs" ml="xs" flex={1} fw={"inherit"}>
            Contrato
          </Text>
          <Text fz="xs" ml="xs" flex={1} c="secondary-color" fw={"bold"}>
            {orderDetail?.results?.orderInfo?.contract}
          </Text>
        </Grid.Col>
        <Grid.Col span={2.4}>
          <Text fz="xs" ml="xs" flex={1} fw={"inherit"}>
            Tipo de afiliado
          </Text>
          <Text fz="xs" ml="xs" flex={1} c="secondary-color" fw={"bold"}>
            {orderDetail?.results?.orderInfo?.patient?.affiliationType}
          </Text>
        </Grid.Col>
        <Grid.Col span={2.4}>
          <Text fz="xs" ml="xs" flex={1} fw={"inherit"}>
            Población
          </Text>
          <Text fz="xs" ml="xs" flex={1} c="secondary-color" fw={"bold"}>
            {orderDetail?.results?.orderInfo?.population}
          </Text>
        </Grid.Col>
      </Grid>
    </Card>
  );

  useIsomorphicEffect(() => {
    refetch();
  }, [orderId]);

  return (
    <Template
      title={showDetail ? "Detalle de la entrega" : "Entrega de Medicamentos"}
      goBackButton={() => {
        goBack();
      }}
    >
      <DeliveryFormToSend
        data={data}
        onClose={() => {
          setDeliveryDrawer(state => ({
            ...state,
            isOpen: false,
          }));
        }}
        isOpen={deliveryDrawer.isOpen}
      />
      {supportFilesModal.isOpen && (
        <DeliverySupportModal
          data={supportFilesModal.data}
          contractId={Number(orderDetail?.results?.orderInfo?.contractId)}
          handleChange={handleSupportFiles}
          onClose={() => {
            setSupportFilesModal(state => ({
              ...state,
              isOpen: false,
            }));
          }}
          open={supportFilesModal.isOpen}
        />
      )}
      <FullDelivery
        dataPatient={orderDetail?.results?.orderInfo}
        onClose={() => {
          setFullDeliveryDrawer(state => ({
            ...state,
            isOpen: false,
          }));
        }}
        isOpen={FullDeliveryDrawer.isOpen}
      />

      <MedicationScanner
        orderId={Number(orderId)}
        isOpen={lotsMedication.isOpen}
        onClose={onCloseLotsModal}
        handleChange={handleChangeQtyLots}
        dataToValidate={{
          dataOrder: orderDetail?.results?.medicines,
          deliveryBy,
          fullDeliveryIsApproved,
        }}
      />
      <DeliveryRegisterPicture
        setData={handlePictureFace}
        data={data?.patientFacePicture}
        isOpen={imagesModal.isOpenFaceModal}
        onClose={() => {
          setImagesModal(state => ({
            ...state,
            isOpenFaceModal: false,
          }));
        }}
      />
      <DeliveryRegisterMultiPicture
        setData={handlePictureDocument}
        data={data?.patientDocumentPictures ?? []}
        isOpen={imagesModal.isOpenDocumentModal}
        onClose={() => {
          setImagesModal(state => ({
            ...state,
            isOpenDocumentModal: false,
          }));
        }}
      />
      <Box>
        <Text fz="md" ml="xs" flex={1} c="brand-color" fw={"bold"}>
          Información del cliente
        </Text>
        {isFreeSale ? clientInformationFreeSale() : clientInformation()}
        <Text fz="md" ml="xs" flex={1} c="brand-color" fw={"bold"}>
          {showDetail ? "Medicamentos entregados" : "Medicamentos a entregar"}{" "}
        </Text>

        <div data-testid="GotoDetail" className="flex justify-end items-center ">
          <Tooltip label="Ver fórmula" withArrow position="right">
            <IconEye
              onClick={() =>
                navigate(routes.getDeliveryDetailOrder, {
                  state: { data: orderId },
                })
              }
              style={{ height: 30, width: 30 }}
              className="primary-color pointer"
            />
          </Tooltip>
        </div>
        {isFreeSale || isFreeSaleWithOrder ? renderTableFreeSale() : renderTable()}
        <Box className="flex justify-end gap-3">
          {!showDetail && !isFreeSale && !isFreeSaleWithOrder && !isPendingCompleteDelivery && (
            <Box className="flex justify-end">
              <Button
                variant="outline"
                onClick={() =>
                  setFullDeliveryDrawer(state => ({
                    ...state,
                    isOpen: true,
                  }))
                }
              >
                Solicitar entrega completa
              </Button>
            </Box>
          )}
          {(isFreeSale || isFreeSaleWithOrder) && (
            <Box className="flex justify-end">
              <Button
                variant="filled"
                color="secondary-color"
                onClick={() => warningNotification("En desarrollo")}
              >
                Cargar ordenamientos
              </Button>
            </Box>
          )}
          <Box className="flex justify-end">
            <Button
              variant="filled"
              onClick={() =>
                showDetail
                  ? warningNotification("En desarrollo")
                  : setLotsMedication(state => ({
                      ...state,
                      isOpen: true,
                    }))
              }
            >
              {showDetail ? " Ver medicamentos escaneados" : "Escanear medicamentos"}
            </Button>
          </Box>
        </Box>

        <Text fz="md" pt={"sm"} ml="xs" flex={1} c="brand-color" fw={"bold"}>
          Registro de entrega
        </Text>
        {!showDetail && (
          <Box className="flex justify-end ">
            <Button
              variant="light"
              data-testid="openSupports"
              onClick={() => {
                setSupportFilesModal(state => ({
                  ...state,
                  isOpen: true,
                }));
              }}
            >
              <IconUpload /> &nbsp; <span> Adjuntar soportes</span>
            </Button>
          </Box>
        )}
        <Box className="flex gap-3" my={"xs"}>
          <Box w={"150%"}>
            <Text fz="xs" ml="xs" flex={1} c="secondary-color" fw={"bold"}>
              Fotografía del rostro
            </Text>
            <div className={`cardCamera ${disabledForm ? "disabled" : ""}`}>
              {data?.patientFacePicture ? (
                <img
                  src={`data:application/pdf;base64,${data?.patientFacePicture}`}
                  alt="pictureFace"
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "2%",
                  }}
                />
              ) : (
                <Text fz="md" ml="xs" fw={"inherit"}>
                  Tomar foto
                </Text>
              )}
              {!disabledForm && (
                <div className="cameraButton">
                  <IconCamera
                    style={{ width: 20, height: 20 }}
                    className="primary-color pointer"
                    onClick={() => {
                      setImagesModal(state => ({
                        ...state,
                        isOpenFaceModal: true,
                      }));
                    }}
                  />
                </div>
              )}
            </div>
          </Box>
          <Box w={"150%"}>
            <Text fz="xs" ml="xs" flex={1} c="secondary-color" fw={"bold"}>
              Fotografía de la identificación
            </Text>
            <div className={`cardCamera ${disabledForm ? "disabled" : ""}`}>
              <Box className={`flex containerDocumentPicture align-center text-center`}>
                {data?.patientDocumentPictures && data?.patientDocumentPictures?.length > 0 ? (
                  <Carousel
                    classNames={classes}
                    withIndicators
                    withControls
                    height={"300px"}
                    slidesToScroll={1}
                    nextControlIcon={
                      <div
                        style={{ backgroundColor: "#fff", borderRadius: "50%", padding: "0.5rem" }}
                      >
                        <IconArrowRight size={16} color="#00b4cd" />
                      </div>
                    }
                    previousControlIcon={
                      <div
                        style={{ backgroundColor: "#fff", borderRadius: "50%", padding: "0.5rem" }}
                      >
                        <IconArrowLeft size={16} color="#00b4cd" />
                      </div>
                    }
                  >
                    {data?.patientDocumentPictures.map((image, ind) => (
                      <Carousel.Slide key={ind} id={`${ind}`}>
                        <img
                          width={"100%"}
                          height={"260px"}
                          src={`data:application/pdf;base64,${image}`}
                          alt="pictureFace"
                        />
                        <Badge variant="light" px={"sm"} color="#00b4cc" size="lg">
                          {ind === 0 ? "Cara frontal" : "Cara posterior"} de documento
                        </Badge>{" "}
                      </Carousel.Slide>
                    ))}
                  </Carousel>
                ) : (
                  <Text fz="md" ml="xs" fw={"inherit"}>
                    Tomar foto
                  </Text>
                )}
                {!disabledForm && (
                  <div className="cameraButton">
                    <IconCamera
                      style={{ width: 20, height: 20 }}
                      className="primary-color pointer"
                      onClick={() => {
                        setImagesModal(state => ({
                          ...state,
                          isOpenDocumentModal: true,
                        }));
                      }}
                    />
                  </div>
                )}
              </Box>
            </div>
          </Box>
          <Box w={"100%"}>
            <Select
              clearable
              disabled={disabledForm}
              data-testid="insurance"
              label="Tipo de documento"
              data={documentList}
              searchable
              onChange={(_, value: IOptionItem) => {
                setData(state => ({
                  ...state,
                  disRequestByDocumentType: value.value,
                  documentPrefix: value.prefix,
                }));
              }}
            />
            <TextInput
              label="No. identificación de quien recibe"
              placeholder="Escribir..."
              type={data?.documentPrefix === "pa" ? "text" : "number"}
              disabled={disabledForm}
              onChange={({ target }) => {
                setData(state => ({
                  ...state,
                  receiveDocument: target.value,
                }));
              }}
            />
            <TextInput
              label="Nombre de quien recibe"
              disabled={disabledForm}
              placeholder="Escribir..."
              onChange={({ target }) => {
                setData(state => ({
                  ...state,
                  receiveName: target.value,
                }));
              }}
            />
            <Box className="flex flex-column" mt="sm">
              <Text fz="xs" flex={1} c="secondary-color" fw={"inherit"}>
                Firma
              </Text>{" "}
              <Box
                className={`col-9 text-primary flex ${disabledForm ? "disabled" : ""}`}
                w={"100%"}
                h={"100%"}
              >
                <SignatureComponent
                  disabled={disabledForm}
                  handleChangeCompanion={handleChangeCompanion}
                  firm={data?.patientSignature}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box py={"md"} className="flex justify-end gap-3">
        {!showDetail && (
          <Box className="flex justify-end">
            <Button variant="outline" onClick={() => goBack()}>
              Cancelar
            </Button>
          </Box>
        )}
        {!showDetail && (
          <Box className="flex justify-end">
            <Button
              variant="gradient"
              onClick={() => {
                onSubmitValidations();
              }}
            >
              Establecer entrega
            </Button>
          </Box>
        )}
      </Box>
    </Template>
  );
};

export default Delivery;
