import DeliveryRegisterPicture from "@app/features/drugDelivery/delivery/components/DeliveryRegisterPicture";
import { Box, Text, Tooltip } from "@mantine/core";
import { IconCamera, IconSignature } from "@tabler/icons-react";
import { useRef, useState } from "react";
import { warningNotification } from "./notifications";

interface SignatureComponentProps {
  firm?: string;
  disabled?: boolean;
  handleChangeCompanion: (value: string) => void;
}

interface SigResponse {
  errorMsg?: string;
  isSigned?: boolean;
  imageData?: string;
  sigString?: string;
}

export default function SignatureComponent({
  firm,
  handleChangeCompanion,
  disabled,
}: SignatureComponentProps) {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [imgWidth, setImgWidth] = useState(500);
  const [imgHeight, setImgHeight] = useState(100);
  const [signPicture, setSignPicture] = useState(false);

  const StartSign = () => {
    const isInstalled = document.documentElement.getAttribute("SigPlusExtLiteExtension-installed");
    if (!isInstalled) {
      warningNotification(
        "La extensión SigPlusExtLite no está instada, o se encuentra deshabilitada. Por favor, instale o habilite la extensión del navegador.",
      );
      return;
    }
    const canvasObj = canvasRef.current;
    if (canvasObj) {
      canvasObj.getContext("2d")?.clearRect(0, 0, canvasObj.width, canvasObj.height);

      setImgWidth(canvasObj.width);
      setImgHeight(canvasObj.height);

      const message = {
        firstName: "",
        lastName: "",
        eMail: "",
        location: "",
        imageFormat: 1,
        imageX: imgWidth,
        imageY: imgHeight,
        imageTransparency: false,
        imageScaling: false,
        maxUpScalePercent: 0.0,
        rawDataFormat: "ENC",
        minSigPoints: 25,
      };

      document.addEventListener("SignResponse", SignResponse, false);
      const messageData = JSON.stringify(message);
      const element = document.createElement("MyExtensionDataElement");
      element.setAttribute("messageAttribute", messageData);
      document.documentElement.appendChild(element);
      const evt = document.createEvent("Events");
      evt.initEvent("SignStartEvent", true, false);
      element.dispatchEvent(evt);
    }
  };

  const SignResponse = (event: Event) => {
    const target = event.target as HTMLElement;
    const str = target.getAttribute("msgAttribute");
    if (str) {
      const obj: SigResponse = JSON.parse(str);
      SetValues(obj);
    }
  };

  const SetValues = (objResponse: SigResponse) => {
    const ctx = canvasRef.current?.getContext("2d");
    if (objResponse.errorMsg) {
      console.error(objResponse.errorMsg);
    } else if (objResponse.isSigned && ctx) {
      if (objResponse.imageData) {
        handleChangeCompanion(objResponse.imageData);
      }

      const img = new Image();
      img.onload = function () {
        ctx.drawImage(img, 0, 0, imgWidth, imgHeight);
      };
      img.src = "data:image/png;base64," + objResponse.imageData;
    }
  };

  return (
    <Box className="cardSignature " h={"9rem"} w={"100%"}>
      <DeliveryRegisterPicture
        setData={handleChangeCompanion}
        data={firm}
        isOpen={signPicture}
        onClose={() => {
          setSignPicture(false);
        }}
      />
      <div>
        {!disabled && (
          <Tooltip label="Firmar con cámara Web" withArrow position="right">
            <div
              className="cameraButtonLeft"
              onClick={() => {
                setSignPicture(true);
              }}
            >
              <IconCamera style={{ width: 20, height: 20 }} className="primary-color pointer" />
            </div>
          </Tooltip>
        )}
        <canvas ref={canvasRef} width="100%" style={{ display: "none" }}></canvas>
        {firm ? (
          <>
            <img
              src={`data:application/pdf;base64,${firm}`}
              alt="pictureFace"
              width={150}
              height={100}
            />
          </>
        ) : (
          <div>
            <Text fz="md" fw={"inherit"} mb={"xl"} ml={0}>
              Seleccione opción para firmar
            </Text>
          </div>
        )}
        {!disabled && (
          <Tooltip label="Firmar con capturadora" withArrow position="right">
            <div className="cameraButton" onClick={StartSign}>
              <IconSignature
                style={{
                  width: 20,
                  height: 20,
                }}
                className="primary-color pointer"
              />
            </div>
          </Tooltip>
        )}
      </div>
    </Box>
  );
}
