import { Center, Loader } from "@mantine/core";
import { useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { hasData } from "@app/common/utils/hasData";
import { parseSearchParams } from "@app/common/utils/parseSearchParams";

import { useAuthentication } from "@app/store/useAuthtentication";

import type { IDataBiowel } from "@app/store/types";
import { useDeliveryData } from "@app/store/useDeliverySearchData";
import { useGenericData } from "@app/store/useGenericData";

const defaultDataSidebar = {
  id: 0,
  isExternal: 0,
  modules: [],
  name: "",
  categoryHomeImage: "",
  categoryImage: "",
  prefix: "",
};

export default function ValidateAuth() {
  const location = useLocation();
  const navigate = useNavigate();
  const { createAuthentication, clearAuthentication } = useAuthentication();
  const { clearGenericData } = useGenericData();
  const { clearDataDispense } = useDeliveryData();

  const parsed = useMemo(() => {
    return parseSearchParams<IDataBiowel>(location.search);
  }, [location.search]);

  useEffect(() => {
    if (
      hasData(parsed.idAccount) &&
      hasData(parsed.idModule) &&
      hasData(parsed.idProfile) &&
      hasData(parsed.idUser) &&
      hasData(parsed.token)
    ) {
      clearGenericData();
      clearDataDispense();
      const userData: IDataBiowel = {
        idAccount: +parsed.idAccount,
        idModule: +parsed.idModule,
        idProfile: +parsed.idProfile,
        idUser: +parsed.idUser,
        fullNameUser: parsed.fullNameUser,
        myPayroll: parsed.myPayroll,
        mySetting: parsed.mySetting,
        nameModule: parsed.nameModule,
        userImage: parsed.userImage,
        token: parsed.token,
      };
      createAuthentication({
        dataBiowel: userData,
        categories: [defaultDataSidebar],
        allCategory: {
          all_Categories: [],
          new_category: [],
        },
      });
    } else {
      clearAuthentication();
    }
    navigate("/", { state: parsed.redirect });
  }, [
    createAuthentication,
    clearAuthentication,
    navigate,
    parsed,
    clearDataDispense,
    clearGenericData,
  ]);

  return (
    <Center h="100vh">
      <Loader size="xl" />
    </Center>
  );
}
